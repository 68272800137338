import React, {useContext, useEffect, useState} from "react"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi";
import { CategoryTree, StaticContext } from "../domain/interfaces";
import Page from "../templates/page"

const ApiGateway = new httpApiGateway()

export default function Home() {

  const api = new httpApiGateway()
  const [context, setContext] = useState<StaticContext | null>(null)

  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
  }, []) // given empty array useEffect run only once

  return context && (
    <Page pageContext={context}> 
    <div className="w-full max-w-screen-md mt-10 mx-auto blog-article">
    <div className="w-full pb-20">
    <h1 className=""> Conditions de Livraison </h1>
    <h3>   LIVRAISON ET MODALITES  </h3>
    <p>Les produits achetés sur le site sont livrés à l'adresse indiquée par le client lors de sa commande ou de son 
        inscription, il est donc tenu responsable de s'assurer que les informations communiqués a CHAMANTINI'S sont 
        exactes, à jour et complètes (tel que : Nom prénom  bien orthographié,  n° de rue , n° de bâtiment, d'escalier, 
        code d’accès,  ville, lieu-dit, département, …). 
        En cas de non-réception du ou des produits dû à une erreur concernant l'adresse de livraison (erronée ou 
        incomplète), CHAMANTINI’S ne sera en aucun cas tenu pour responsable du retard, du retour ou de la 
        non-livraison de la commande, et par conséquent en cas de retour des produits CHAMANTINI'S sera en droit de 
        réclamer au client le règlement des nouveaux frais de livraison. 
    </p>
    <p>
        La livraison est gratuite pour la Réunion et elle est partiellement offerte pour toutes les autres destinations. 
        Les frais d'envoi varient selon la destination ; le poids et la valeur de votre commande, votre panier  indiquera les modes de livraison adaptés à votre commande et son coût. 
    </p>
    <p>
        Les expéditions se font les mercredi et les samedi matin. Les commandes passées du samedi au mardi 
        avant 17h00 seront expédiées le mercredi. Les commandes passées du mercredi au vendredi avant 
        17h00 seront expédiées le samedi matin.
    </p>
    <p>
    Les délais de livraison sont données à titre indicatifs. Elles peuvent parfois être rallongés pour de 
    multiples raisons telles que grève des services postaux, surcharge de travail, périodes festives, grève 
    de divers transporteurs, intempéries, erreur de routage, et tout autre élément perturbateur indépendant 
    de ma volonté. 
    </p>
    <p>Les retards de livraison ne peuvent donner lieu à des dommages et intérêts, à retenue, remboursement, ni à 
        l’annulation des commandes en cours. 
    </p>
    <p>En cas de non-livraison, toute réclamation passée au-delà de 30 jours (à compter de la date d’expédition) ne sera 
        pas prise en compte. 
    </p>
    <p>A la livraison le client doit vérifier l’état de l'emballage, des produits, s’il constate des dommages, il est tenu de 
        le signaler au transporteur (sur le bon de livraison : constat des dégâts écrit de manière précise et complète, et 
        daté et signé). Il est également tenu d'en informer CHAMANTINI'S. 
        Dans le cas où le colis est abîmé ou est reconditionné par la poste, le client peut le refuser. Dans ce cas, il doit faire 
        une réclamation au bureau de poste de sa région et de me faire part de son refus; photos et réclamation faite à  
        l'appui. Si le client accepte le colis abîmé ou reconditionné, il en assumera l’entière responsabilité et n'aura 
        aucun recours possible, ni remboursement. 
    </p>
    <p>Les frais d’expéditions, les frais de douane ou autre taxe locale sont à la charge du client </p>
    <p>Si le client opte pour une livraison en lettre suivi sans signature, le colis est déposé dans sa boite aux lettres sans 
        signature. En cas de perte, vol, dégradation quelconque ou non remis, il n'y a pas de recours possible auprès de la 
        poste et par conséquent pas d’indemnisation possible. 
    </p>
    <p>Les frais de livraison sont calculés sur une base forfaitaire, selon le poids et la valeur du produit </p>
    <p>CHAMANTINI'S se réserve le droit de modifier les frais de livraison à tout moment selon l’évolution des tarifs 
        de la poste. 
    </p>
    <p>
    Pour les envois en lettre suivi, lettre ou paquet recommandée, à l'expédition vous recevrez une 
        confirmation d'envoi avec votre numéro de suivi. <br/>
            Vous pourrez suive l'acheminement de celui-ci sur le site officiel de la Poste : <br/>
        <a>http://www.csuivi.courrier.laposte.fr/suivi </a>
        
        
        Attention : Avec le mode de livraison courrier suivi sans signature, celui-ci est déposé dans votre boite 
        aux lettres, sans signature. En cas de perte, vol, colis endommagé ou non remis, il n'y à pas de recours 
        possible auprès de la Poste, et par conséquent pas d'indemnisation possible. Mode de livraison 
        uniquement pour les commandes d'une valeur inférieur à 100 euros. <br/>
        
        Pour les commandes de 100 euros et plus la livraison se fera en courrier recommandé car ce mode de 
        livraison est assuré. 
    </p>
    </div>
    </div>
    </Page>
  )

}
